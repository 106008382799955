import React, { useState } from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import ModalVideo from "react-modal-video";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import { Link } from "gatsby";
import Blog2 from "components/Software/Blog2";
import Codei from "components/Preview/Codei";


const MtLoan = () => {

  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  }
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0">
        <div className="container">
          <div className="content section-padding">
            <div className="row flex">
              <div className="col-lg-5">
                <div className="info" style={{ background: '#00000000'  }}>
                  <h1 className="h1">
                    Get your funds in less than <span> 10 minutes!</span>
                  </h1>
                  <p className="p">
                    Enjoy quick and easy access to funds faster than you can
                    blink.
                  </p>
                </div>
              </div>
              <div className="col-lg-2">
            
              </div>
              <div className="col-lg-5">
            <div className="main-vid ">
              <img src="/assets/img/viv.png" alt="" />
              <a href="https://youtu.be/EI_UzjfdpkE" onClick={openVideo} className="play-icon">
                <i className="fas fa-play"></i>
              </a>
            </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-img ">
          <img src="/assets/img/about/style_3_1.png" alt="" />
        </div>
        {
        typeof window !== "undefined" && 
          (
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="EI_UzjfdpkE"
              onClose={() => setOpen(false)}
            />
          )
      }
      </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img">
                  <img
                    src="/assets/img/about/MTLOANS.png"
                    alt=""
                    className="img-body"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>MTLoans </h3>
                  </div>
                  <p className="p">
                    Whether you need a personal loan, a business loan, or
                    working capital, we offer flexible repayment options and
                    competitive interest rates to meet your financial needs. Get
                    approved in minutes and secure the funds to achieve your
                    goals.
                  </p>
                  <div className="text mb-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>BVN</li>
                      <li>Email</li>
                      <li>Employer Details</li>
                      <li>Salary Account Details</li>
                      <li>Loan amount & Tenor</li>
                    </ul>
                  </div>
                  <a
                    href="https://mtloans.ng/login?r=DM1"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Apply for MTLoan</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Blog2 />
        <Codei /> 
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>MTLoans - Apply for a Loan and Get Credited In 10 Minutes</title>
      <meta name="description" content="Apply for an MTLoan of up to N5 million and get your loan approved in 10 minutes or less! No Collateral or paperwork required for your loan." />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default MtLoan;








